<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <div class="row">
        <div class="col">
          <b-form-group
            id="input-group-trigger-modal-citizen-4"
            label="Penduduk:"
            label-for="input-trigger-modal-citizen-4"
          >
            <b-form-input
              id="input-trigger-modal-citizen-4"
              v-model="display.citizen_name"
              placeholder="Pilih Penduduk"
              readonly
              @click="$bvModal.show('modal-citizen-4')"
            >
            </b-form-input>
            <small class="text-danger">{{ error.citizen_id }}</small>
          </b-form-group>

          <div class="row">
            <div class="col-sm-12">
              <b-button
                variant="info"
                block
                @click="$bvModal.show('modal-material-multiple')"
              >
                Pilih Bahan
              </b-button>
            </div>
            <div class="col-sm-12 pt-2">
              <table class="table thead-light">
                <thead class="thead-light">
                  <tr>
                    <th>No</th>
                    <th>Bahan</th>
                    <th>Jumlah</th>
                    <th></th>
                  </tr>
                </thead>
                <tr v-for="(labMaterial, i) in labMaterialCollection" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ labMaterial.material_name }}</td>
                  <td>{{ labMaterial.quantity }}</td>
                  <td>
                    <b-button
                      class="ml-2"
                      variant="success"
                      v-b-tooltip.hover
                      title="Edit Jumlah"
                      @click="editLabMaterial(i)"
                      size="sm"
                    >
                      <i class="fas fa-edit px-0"></i>
                    </b-button>
                    <b-button
                      class="ml-2"
                      variant="danger"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteLabMaterial(i)"
                      size="sm"
                    >
                      <i class="fas fa-trash px-0"></i>
                    </b-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- Description input -->
          <b-form-group id="input-group-notes">
            <label for="input-notes"
              >Keterangan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-notes"
              v-model="form.notes"
              placeholder="Keterangan Persediaan Bahan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.notes }}</small>
          </b-form-group>
        </div>
      </div>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/material-distributions/list')"
      >
        Batal
      </b-button>
    </b-form>

    <ModalCitizen4 @chooseCitizen="chooseCitizen" />
    <ModalMaterialMultiple
      @chooseLabMaterial="chooseLabMaterial"
      @unCheckLabMaterial="unCheckLabMaterial"
      :labMaterialCollection="labMaterialCollection"
    />

    <!-- Modal Quantity -->
    <b-modal
      id="modal-lab-material-quantity"
      @ok="addLabMaterial"
      @cancel="cancelModal"
    >
      <template #modal-title>Form Input Jumlah Bahan</template>
      <b-form-group
        id="input-group-quantity"
        label="Jumlah:"
        label-for="input-quantity"
      >
        <b-form-input
          id="input-quantity"
          v-model="labMaterialCollectionForm.quantity"
          placeholder="Jumlah"
          type="number"
          required
        ></b-form-input>
        <small class="text-danger">{{
          labMaterialCollectionError.quantity
        }}</small>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalCitizen4 from "@/view/components/general/ModalCitizen4.vue";
import ModalMaterialMultiple from "@/view/components/general/ModalMaterialMultiple.vue";

export default {
  props: {
    form: Object,
    route: String,
  },

  components: {
    ModalCitizen4,
    ModalMaterialMultiple,
  },

  data() {
    return {
      // Error
      error: {
        notes: "",
        citizen_id: "",
      },
      display: {
        citizen_name: "",
      },
      formData: new FormData(),
      labMaterialCollection: [],
      labMaterialCollectionDeleted: [],
      labMaterialCollectionForm: {
        material_id: "",
        quantity: "",
        remaining_stock: "",
        material_name: "",
      },
      labMaterialCollectionError: {
        material_id: "",
        quantity: "",
        remaining_stock: "",
        material_name: "",
      },
      labMaterialIndex: "",
      labMaterialPurpose: "add",
    };
  },

  methods: {
    chooseCitizen(value) {
      this.form.citizen_id = value.id;
      this.display.citizen_name = value.name;
      this.$bvModal.hide("modal-citizen-4");
      console.log("hide");
    },
    chooseLabMaterial(data) {
      this.labMaterialPurpose = "add";
      this.labMaterialCollectionForm.material_id = data.id;
      this.labMaterialCollectionForm.remaining_stock = data.remaining_stock;
      this.labMaterialCollectionForm.material_name = data.name;
      this.labMaterialCollectionForm.quantity = "";
      this.$bvModal.show("modal-lab-material-quantity");
    },
    validationLabMaterialQuantity() {
        let status = true;
      if (this.labMaterialCollectionForm.quantity == "") {
        status = false;
        this.labMaterialCollectionError.quantity = "Kolom jumlah wajib diisi";
      } else {
        this.labMaterialCollectionError.quantity = "";
      }
      if (this.labMaterialCollectionForm.quantity > this.labMaterialCollectionForm.remaining_stock) {
        status = false;
        this.labMaterialCollectionError.quantity = `Stok bahan hanya tersisa ${this.labMaterialCollectionForm.remaining_stock}`;
      } else {
        this.labMaterialCollectionError.quantity = "";
      }
      return status;
    },
    addLabMaterial(bvModalEvt) {
      if (this.validationLabMaterialQuantity() == false) {
        bvModalEvt.preventDefault();
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("validation error");
      }

      if (this.labMaterialPurpose == "add") {
        if (!this.form.hasOwnProperty("oldLabMaterialCollectionFlag")) {
          this.setOldLabMaterialCollection();
        }
        let clone = { ...this.labMaterialCollectionForm };
        this.labMaterialCollection.push(clone);
        Swal.fire({
          title: "Berhasil",
          text: "Berhasil memilih bahan",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        if (!this.form.hasOwnProperty("oldLabMaterialCollectionFlag")) {
          this.setOldLabMaterialCollection();
        }
        Swal.fire({
          title: "Berhasil",
          text: "Berhasil edit bahan",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        // this.labMaterialCollection[this.labMaterialIndex].name = // this.labMaterialCollection.name;
        this.labMaterialCollection[this.labMaterialIndex].quantity =
          this.labMaterialCollectionForm.quantity;
      }
      this.$bvModal.hide("modal-lab-material-quantity");
      this.$bvModal.hide("modal-material-multiple");
      console.log('ben', this.labMaterialCollection)
      this.resetLabMaterial();
    },
    cancelModal() {
      this.$root.$emit("cancelModal", this.labMaterialCollectionForm);
    },
    resetLabMaterial() {
      this.labMaterialCollectionForm.quantity = "";
    },
    unCheckLabMaterial(value) {
      for (let a = 0; a < this.labMaterialCollection.length; a++) {
        if (this.labMaterialCollection[a].material_id == value.id) {
          this.labMaterialCollection.splice(a, 1);
        }
      }
    },
    deleteLabMaterial(index) {
      if (!this.form.hasOwnProperty("oldLabMaterialCollectionFlag")) {
        this.setOldLabMaterialCollection();
      }
      this.labMaterialCollection.splice(index, 1);
    },
    editLabMaterial(i) {
      this.labMaterialPurpose = "edit";
      this.labMaterialIndex = i;

      this.labMaterialCollectionForm.id = this.labMaterialCollection[i].id;
      this.labMaterialCollectionForm.name = this.labMaterialCollection[i].name;
      this.labMaterialCollectionForm.quantity =
        this.labMaterialCollection[i].quantity;
      this.labMaterialCollectionForm.remaining_stock =
        this.labMaterialCollection[i].remaining_stock;

      this.$bvModal.show("modal-lab-material-quantity");
    },
    setOldLabMaterialCollection() {
      this.form.oldLabMaterialCollectionFlag = JSON.stringify(
        this.form.lab_material_id
      );
      this.formData.append(
        "oldLabMaterialCollection",
        JSON.stringify(this.form.lab_material_id)
      );
    },
    async formOnsubmit() {
      this.form.material_selected = JSON.stringify(this.labMaterialCollection);
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/material-distributions/list");
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>
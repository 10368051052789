var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row justify-content-end mt-n3"},[_c('div',{staticClass:"col-md-3"},[_c('b-input-group',[_c('treeselect',{attrs:{"multiple":false,"options":_vm.material_categories},on:{"input":_vm.filterByInventoryCategory},model:{value:(_vm.filter.material_category_id),callback:function ($$v) {_vm.$set(_vm.filter, "material_category_id", $$v)},expression:"filter.material_category_id"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{attrs:{"squared":"","variant":"success"},on:{"click":_vm.filterByName}},[_vm._v("Cari")]),_c('b-button',{attrs:{"squared":"","variant":"danger"},on:{"click":_vm.resetFilter}},[_vm._v("Reset")])]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Saring Nama"},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]),_c('b-table',{staticClass:"mt-3",style:('white-space: nowrap'),attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"responsive":"sm"},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'actions' ? '20%' : '' })})})}},{key:"cell(photo)",fn:function(data){return [_c('div',{staticClass:"image-input-wrapper"},[_c('img',{staticClass:"image",attrs:{"src":data.item.photo}})])]}},{key:"cell(actions)",fn:function(data){return [(_vm.purpose == 'modal' && _vm.secondPurpose == 'modal')?[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.chosenItem({
                  id: data.item.id,
                  name: data.item.name,
                })}}},[_vm._v(" Pilih ")])]:_vm._e(),(_vm.purpose == 'modal-material-multiple')?[_c('b-form-checkbox',{attrs:{"checked":data.item.checked,"id":`checkbox-pick-item-${data.item.id}`,"name":`checkbox-pick-item-${data.item.id}`},on:{"change":function($event){return _vm.chooseLabMaterial(
                  {
                    id: data.item.id,
                    name: data.item.name,
                    remaining_stock: data.item.material_remaining_stock,
                  },
                  $event
                )}}})]:_vm._e()]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-modal id="modal-citizen-4" size="xl" title="Pilih Penduduk" hide-footer>
      <Table purpose="modal-citizen" @chooseCitizen="chooseCitizen"  :source="source"/>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/citizen/Table.vue";

export default {
  props:{
    source:{
      type: String,
      default: ""
    }
  },
  components: {
    Table,
  },
  methods: {
    chooseCitizen(value) {
      this.$emit("chooseCitizen", value);
    },
  },
};
</script>

<style>
</style>
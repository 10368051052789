<template>
  <div>
    <b-modal
      id="modal-material-multiple"
      size="xl"
      title="Pilih Bahan"
      hide-footer
    >
      <Table purpose="modal-material-multiple" @unCheckLabMaterial="unCheckLabMaterial" @chooseLabMaterial="chooseLabMaterial" :labMaterialCollection="labMaterialCollection"/>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/materials/Table.vue";

export default {
  props: {
      labMaterialCollection: Array
  },

  components: {
    Table,
  },

  methods: {
    chooseLabMaterial(value) {
      this.$emit("chooseLabMaterial", value);
    },
    unCheckLabMaterial(value){
      this.$emit("unCheckLabMaterial", value)
    }
  },
};
</script>

<style>
</style>
